import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import InstallAlert from '@/components/installAlert';
import {user, UserInfo} from '@/store';
import {likeBrand} from '@/services/brands';

import styles from './styles.module.scss';

// 브랜드 아이템 인터페이스
export interface CourseBrandItem {
  id: number;
  parentId: number;
  title: string;
  collectionTitle: string | null;
  collectionDesc: string | null;
  collectionImage: string | null;
  info: string;
  separator: string;
  thumbnailUrl: string;
  address1: number;
  address2: number;
  imageList: string[];
  lat: number;
  lon: number;
  is_bookmark?: boolean;
  keyword: string;
}

// 코스 아이템 인터페이스
export interface CourseListItem {
  id: number;
  title: string;
  subtitle: string | null;
  image: string | null;
  type: string;
  brandList: CourseBrandItem[];
}

interface Props {
  itemList: CourseListItem[];
}

function BrandItem({
  item,
  idx,
  toggleLike,
}: {
  item: CourseBrandItem;
  idx: number;
  toggleLike: (idx: number, isLike: boolean) => void;
}) {
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const _doBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.preventDefault();
    $event.stopPropagation();
    try {
      if (userInfo.id == -1) {
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const id = item.parentId;
        const next = !item.is_bookmark;
        await likeBrand(accessToken, id, next);
        toggleLike(idx, next);
        console.log(item);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div
      className={styles.item_wrapper}
      onClick={() => navigator(`/brand/${item.parentId}/${item.id}`)}>
      <div className={styles.img_wrapper}>
        <img src={item.collectionImage || item.imageList[0]} />
        <div className={styles.like_wrapper} onClick={_doBookmark}>
          {!item.is_bookmark ? (
            <span
              className={`icon ${styles.heart_empty} active ${styles.heart_icon}`}>
              &nbsp;
            </span>
          ) : (
            <span
              className={`icon ${styles.heart_filled} ${styles.heart_icon}`}>
              &nbsp;
            </span>
          )}
        </div>
      </div>
      <div className={styles.txt_wrapper}>
        <h3>{item.title}</h3>
        <p>{item.keyword}</p>
      </div>
    </div>
  );
}

function CourseItem({item}: {item: CourseListItem}) {
  const [brandList, setBrandList] = useState<CourseBrandItem[]>([]);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = [...brandList];
    _items[idx].is_bookmark = isLike;
    setBrandList(_items);
  };
  useEffect(() => {
    setBrandList(item.brandList);
  }, [item]);
  return (
    <div>
      <h2 className={styles.courseTitle}>{item.title}</h2>
      <div className={styles.listArea}>
        <div className={styles.list} style={{paddingBottom: '40px'}}>
          {brandList.map((brand, idx) => {
            return (
              <BrandItem
                key={`brand_item_${idx}`}
                idx={idx}
                item={brand}
                toggleLike={toggleLike}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default function CourseList({itemList}: Props) {
  const [openInstallAlert, setOpenInstallAlert] = useState(false);
  const [, setHasMore] = useState(false);
  const [maxCount] = useState(0);

  useEffect(() => {
    if (maxCount > 0 && itemList.length > 0) {
      setHasMore(itemList.length < maxCount);
    }
  }, [itemList, maxCount]);

  return (
    <div className={styles.collectionWrap}>
      <>
        {itemList.length === 0 ? (
          <div className={styles.no_list}></div>
        ) : (
          <div>
            {itemList.map((item, idx) => {
              return idx != itemList.length - 1 ? (
                <>
                  <CourseItem key={`course_item_${idx}`} item={item} />
                  <div
                    style={{
                      height: '8px',
                      width: '100%',
                      background: '#F7F7FC',
                    }}></div>
                </>
              ) : (
                <CourseItem key={`course_item_${idx}`} item={item} />
              );
            })}
          </div>
        )}
      </>
      <InstallAlert
        ignoreRead={openInstallAlert}
        setIgnore={setOpenInstallAlert}
      />
    </div>
  );
}
