import React, {useRef} from 'react';
import cc from 'classcat';

import useImageHeight from '@/hooks/useImageHeight';
import {addComma} from '@/utils';

import styles from './styles.module.scss';

interface Props {
  wrapperStyle?: React.CSSProperties;
  title: string;
  titleWrapperStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  link?: string;
  totalCnt?: number;
  subtitle?: string;
  images: {
    id?: number;
    parentId?: number;
    url: string;
    title: string;
    description: string;
    price?: number;
    discountNumber?: number;
    keyword?: string;
  }[];
  noFirstMargin?: boolean;
  imageMarginLeft?: number; // Minimal margin-left(4px) depends on styles.modeul.scss
  disableDescription?: boolean;
  withTwoLineTitle?: boolean;
  itemTitleStyle?: React.CSSProperties;
  itemDescStyle?: React.CSSProperties;
  callbackItemClick?: <T>(item: T) => void;
  callbackMoreIcon?: () => void;
  isTogether?: boolean;
  noMargin?: boolean;
  subCuration?: boolean;
}

export default function ImageRail({
  wrapperStyle,
  title,
  subtitle,
  titleWrapperStyle,
  titleStyle,
  subtitleStyle,
  link,
  images,
  totalCnt,
  disableDescription,
  withTwoLineTitle,
  imageMarginLeft,
  noFirstMargin,
  itemTitleStyle,
  itemDescStyle,
  subCuration,
  callbackItemClick,
  callbackMoreIcon,
}: Props) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const {height: imgHeight, imageRef: imgRef, setHeight} = useImageHeight();
  const subCurationStyle: React.CSSProperties = subCuration
    ? {
        minWidth: '160px',
        width: '160px',
        borderRadius: '0',
      }
    : {};
  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={styles.title} style={titleStyle}>
          <h3>{title}</h3>
          {subtitle && (
            <span className={styles.subtitle} style={subtitleStyle}>
              {subtitle}
            </span>
          )}
        </div>
        {!!totalCnt && totalCnt > 5 && (
          <div
            className={styles.more}
            onClick={() => (callbackMoreIcon ? callbackMoreIcon() : '')}
            style={{display: link ? 'block' : 'none', cursor: 'pointer'}}>
            <span>더보기</span>
          </div>
        )}
      </div>
      <div className={styles.carousel}>
        <div className={styles.images} ref={carouselRef} id={'img'}>
          {images.length > 0 &&
            images.map((o, i) => {
              return (
                <div
                  className={styles.brandItem}
                  key={`${o.url}_${i}`}
                  onClick={() =>
                    callbackItemClick ? callbackItemClick(o) : ''
                  }
                  style={{
                    marginLeft:
                      noFirstMargin && i === 0
                        ? 0
                        : i !== 0
                        ? imageMarginLeft != undefined
                          ? imageMarginLeft + 'px'
                          : '20px'
                        : '20px',
                    ...subCurationStyle,
                  }}>
                  <img
                    ref={imgRef}
                    src={o.url}
                    alt=""
                    style={{
                      height: `${imgHeight}px`,
                      ...subCurationStyle,
                    }}
                    onLoad={() => {
                      if (imgRef.current) {
                        setHeight(imgRef.current.getBoundingClientRect().width);
                      }
                    }}
                  />
                  <div
                    className={cc([
                      styles.itemTitle,
                      withTwoLineTitle && styles.twoLines,
                    ])}
                    style={itemTitleStyle}>
                    {o.title}
                  </div>
                  {!disableDescription && (
                    <p className={styles.itemDesc} style={itemDescStyle}>
                      {o.description.replace('\\n', ' ')}
                    </p>
                  )}
                  {o?.price && (
                    <p className={styles.itemPrice}>
                      {!!o?.discountNumber && <span>{o?.discountNumber}%</span>}
                      {addComma(o?.price)}
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
